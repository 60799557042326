body,
input,
textarea {
  font-family: $fontFamily;
  font-size: $baseFontSize + px;
  line-height: 1.5;
  color: #111;
  letter-spacing: (25em / 1000);
  font-feature-settings: 'palt';
  backface-visibility: hidden;
}

body {
  min-width: 1000px;
}

.aica-app {
  position: relative;
  min-width: 1000px;
}
